<template>
  <div>
    <div class="keditor-article"></div>
    <modal-delete-snippet @delete-snippet="deleteSnippet"/>
    <modal-uploading-image
      :image-object="imageUrlForModalImageUpload"
      :type="computedMediaType"
      :personalization="personalizedData"
      @image-uploaded="addImageToEditor"
    />
    <modal-personalization-editor
      v-if="showPersonalizationModal"
      :personalization="personalizedData"
      :context-data="contextData"
      @editor-context="addContextToEditor"
      @cancel-modal="showPersonalizationModal = false"
    />
  </div>
</template>

<script>
import 'simplebar/dist/simplebar.css';
import '@/webmagv1/libs/spectrum.css';
import SetAutosaveIndicatorForHeaderInCache from '@/graphQlQueries/mutations/setAutosaveIndicatorForHeaderInCache';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

import $ from 'jquery';

// import FroalaEditor from 'froala-editor';
import SimpleBar from 'simplebar';
import Sortable from 'sortablejs';

/*
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/languages/de';
*/

import 'js-cloudimage-responsive';

import '@/webmagv1/libs/spectrum';
import '@/webmagv1/libs/selectize.min';

import '../../../files/wps/_keditor_shared/plugins/bootstrap-iconpicker-1.10.0/dist/js/bootstrap-iconpicker.bundle.min';
import '../../../files/wps/_keditor_shared/plugins/rangeslider/rangeslider';
import '../../../files/wps/_keditor_shared/plugins/jquery-idle/jquery.idle';
import '../../../files/wps/_keditor_shared/js/keditor_custom';
import '../../../files/wps/_keditor_shared/js/keditor';
import '../../../files/wps/_keditor_shared/js/template.polyfill';
import '../../../files/wps/_keditor_shared/js/keditor-component-builder';
import '../../../files/wps/_keditor_shared/js/keditor-component-text';

import PageTourMixin from '@/mixins/tour/pageTourMixin';

// global.editor = FroalaEditor;
// https://github.com/typekit/webfontloader

/*
import '@/webmagv1/_app_dev/plugins/photoswipe/photoswipe.min';
import '@/webmagv1/_app_dev/plugins/photoswipe/photoswipe-ui-default.min';
import '@/webmagv1/_app_dev/js/webfont';
import '@/webmagv1/_app_dev/js/img-cover';

import '../../../files/_app_dev/plugins/photoswipe/photoswipe.min';
import '../../../files/_app_dev/plugins/photoswipe/photoswipe-ui-default.min';
import '../../../files/_app_dev/js/webfont';
import '../../../files/_app_dev/js/img-cover';
*/

// import files as raw files so they dont have to be loaded dynamically in the keditor iframe
// eslint-disable-next-line
import photoswipe from '!raw-loader!../../../files/_app_dev/plugins/photoswipe/photoswipe.css';
// eslint-disable-next-line
import defaultskin from '!raw-loader!../../../files/_app_dev/plugins/photoswipe/default-skin/default-skin.css';
// eslint-disable-next-line
import swiper from '!raw-loader!../../../files/_app_dev/plugins/swiper/swiper.css';
// eslint-disable-next-line
import imgCover from '!raw-loader!../../../files/_app_dev/css/img-cover.css';
// eslint-disable-next-line
import pgsFramework from '!raw-loader!../../../files/_app_dev/css/pgs-framework.css';
// eslint-disable-next-line
import themes from '!raw-loader!../../../files/_app_dev/css/themes.css';
// eslint-disable-next-line
import fontAwesome from '!raw-loader!../../../files/_app_dev/css/font-awesome.min.css';
// eslint-disable-next-line
import spectrum from '!raw-loader!../../../files/wps/_keditor_shared/spectrum.css';
// eslint-disable-next-line
import selectize from '!raw-loader!../../../files/wps/_keditor_shared/selectize.css';
// eslint-disable-next-line
import keditor from '!raw-loader!../../../files/wps/_keditor_shared/css/keditor-1.1.5.min.css';
// eslint-disable-next-line
import keditorCustom from '!raw-loader!../../../files/wps/_keditor_shared/css/keditor_custom.css';
// eslint-disable-next-line
import simplebar from '!raw-loader!../../../files/wps/_keditor_shared/plugins/simplebar/simplebar.min.css';
// eslint-disable-next-line
import froalaEditor from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/froala_editor.css';
// eslint-disable-next-line
import froalaStyle from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/froala_style.min.css';
// eslint-disable-next-line
import froalaColors from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/colors.min.css';
// eslint-disable-next-line
import froalaTable from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/table.min.css';
// eslint-disable-next-line
import froalaEmoticons from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/emoticons.min.css';
// eslint-disable-next-line
import froalaCharacters from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/special_characters.min.css';
// eslint-disable-next-line
import froalaHelp from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/help.min.css';
// eslint-disable-next-line
import froalaImage from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/image.min.css';
// eslint-disable-next-line
import froalaVideo from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/video.min.css';
// eslint-disable-next-line
import froalaCodeView from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/code_view.min.css';
// eslint-disable-next-line
import froalaDraggable from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/plugins/draggable.min.css';
// eslint-disable-next-line
import froalaEmebdly from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/css/third_party/embedly.min.css';
// eslint-disable-next-line
import froalaOembed from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala-oembed-main/src/froala-oembed.css';
// eslint-disable-next-line
import bootstrapIconpicker from '!raw-loader!../../../files/wps/_keditor_shared/plugins/bootstrap-iconpicker-1.10.0/dist/css/bootstrap-iconpicker.min.css';
// eslint-disable-next-line
import rangeslider from '!raw-loader!../../../files/wps/_keditor_shared/plugins/rangeslider/rangeslider.css';
// eslint-disable-next-line
import xncolorpickerCSS from '!raw-loader!../../../files/wps/_keditor_shared/plugins/xncolorpicker/xncolorpicker.css';
// eslint-disable-next-line
import xncolorpickerJs from '!raw-loader!../../../files/wps/_keditor_shared/plugins/xncolorpicker/dist/xncolorpicker.min.js';
// eslint-disable-next-line
import webfontJs from '!raw-loader!../../../files/_app_dev/js/webfont.js';
// eslint-disable-next-line
import libPublicJs from '!raw-loader!../../../files/_app_dev/js/lib.public.js';
// eslint-disable-next-line
import libThemeJs from '!raw-loader!../../../files/_app_dev/js/lib.theme.js';
// eslint-disable-next-line
import swiperJs from '!raw-loader!../../../files/_app_dev/plugins/swiper/swiper.js';
// eslint-disable-next-line
import imgCoverJs from '!raw-loader!../../../files/_app_dev/js/img-cover.js';

// eslint-disable-next-line
import froala from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/froala_editor.min.js';
// eslint-disable-next-line
import align from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/align.min.js';
// eslint-disable-next-line
import colors from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/colors.min.js';
// eslint-disable-next-line
import emoticons from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/emoticons.min.js';
// eslint-disable-next-line
import font_family from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/font_family.min.js';
// eslint-disable-next-line
import font_size from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/font_size.min.js';
// eslint-disable-next-line
import help from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/help.min.js';
// eslint-disable-next-line
import image from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/image.min.js';
// eslint-disable-next-line
import inline_class from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/inline_class.min.js';
// eslint-disable-next-line
import inline_style from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/inline_style.min.js';
// eslint-disable-next-line
import line_breaker from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/line_breaker.min.js';
// eslint-disable-next-line
import line_height from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/line_height.min.js';
// eslint-disable-next-line
import link from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/link.min.js';
// eslint-disable-next-line
import lists from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/lists.min.js';
// eslint-disable-next-line
import paragraph_format from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/paragraph_format.min.js';
// eslint-disable-next-line
import paragraph_style from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/paragraph_style.min.js';
// eslint-disable-next-line
import special_characters from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/special_characters.min.js';
// eslint-disable-next-line
import table from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/table.min.js';
// eslint-disable-next-line
import url from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/url.min.js';
// eslint-disable-next-line
import video from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/video.min.js';
// eslint-disable-next-line
import code_view from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/code_view.min.js';
// eslint-disable-next-line
import word_paste from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/plugins/word_paste.min.js';
// eslint-disable-next-line
import froala_embedly from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/third_party/embedly.min.js';
// eslint-disable-next-line
import froala_oembed from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala-oembed-main/src/froala-oembed.js';
// eslint-disable-next-line
import froala_fa from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/third_party/font_awesome.min.js';
// eslint-disable-next-line
import froala_de from '!raw-loader!../../../files/wps/_keditor_shared/plugins/froala_editor_4.1.4/js/languages/de.js';

// eslint-disable-next-line no-unused-vars,global-require
const Handlebars = require('handlebars');

export default {
  name: 'KEditor',
  components: {
    ModalDeleteSnippet: () => import('@/components/modals/ModalDeleteSnippet.vue'),
    ModalUploadingImage: () => import('@/components/modals/ModalUploadImage.vue'),
    ModalPersonalizationEditor: () => import('@/components/modals/ModalPersonalizationEditor.vue'),
  },
  props: {
    theme: {
      type: String,
    },
    collection: {
      type: String,
    },
    content: {
      type: String,
    },
    snippets: {
      type: Array,
    },
    templateName: {
      type: String,
    },
    corporateIdentity: {
      type: Object,
    },
    variablesPluginData: {
      type: Array,
    },
    chatGPTPluginData: {
      type: Object,
    },
    groupSettings: {
      type: Object,
    },
    privileges: {
      type: Array,
    },
    pageSiblings: {
      type: Array,
    },
    prevNextPageSiblings: {
      type: Object,
    },
    customCss: {
      type: String,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: null,
    },
    pageLanguage: {
      type: String,
      default: null,
    },
    personalizedData: {
      type: Array,
      default: null,
    },
    formsData: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    corporateIdentityEmptyObject: {
      _customColor: ['', '', '', ''],
      _customFonts: {
        google: ['', ''],
      },
    },
    groupSettingsEmptyObject: {},
    cssFilesRaw: [
      photoswipe,
      defaultskin,
      swiper,
      imgCover,
      pgsFramework,
      themes,
      fontAwesome,
      spectrum,
      selectize,
      keditor,
      keditorCustom,
      simplebar,
      froalaEditor,
      froalaStyle,
      froalaColors,
      froalaTable,
      froalaEmoticons,
      froalaCharacters,
      froalaHelp,
      froalaImage,
      froalaVideo,
      froalaCodeView,
      froalaDraggable,
      froalaEmebdly,
      froalaOembed,
      bootstrapIconpicker,
      rangeslider,
      xncolorpickerCSS,
    ],
    acceptedFileTypes: null,
    mediaUploadFileType: 'image',
    cssFiles: [],
    jsFiles: [],
    jsFilesRaw: [
      froala,
      align,
      colors,
      emoticons,
      font_family,
      font_size,
      help,
      image,
      inline_class,
      inline_style,
      line_breaker,
      line_height,
      link,
      lists,
      paragraph_format,
      paragraph_style,
      special_characters,
      table,
      url,
      video,
      code_view,
      word_paste,
      froala_embedly,
      froala_oembed,
      froala_fa,
      froala_de,
      webfontJs,
      libPublicJs,
      libThemeJs,
      swiperJs,
      imgCoverJs,
      xncolorpickerJs,
    ],
    keditor: null,
    editorData: null,
    displayData: null,
    confirmDeleteCallbackReference: null,
    uploadImageCallbackReference: null,
    contextCallbackReference: null,
    snippetUrls: null,
    isNewPage: false,
    imageUrlForModalImageUpload: {
      url: null,
      width: null,
      height: null,
      focalpoint: {
        x: 50,
        y: 50,
      },
    },
    contextData: null,
    showPersonalizationModal: false,
  }),
  mixins: [PageTourMixin],
  computed: {
    computedMediaType() {
      return this.mediaUploadFileType;
    },
  },
  mounted() {
    if (this.$route.query.page && this.$route.query.page === 'new') {
      this.isNewPage = true;
    }
    // initialize global variables for KEditor
    // global.FroalaEditor = FroalaEditor;
    global.Sortable = Sortable;
    // global.Swiper = Swiper;
    global.SimpleBar = SimpleBar;
    global.Handlebars = Handlebars;

    const {
      cssFiles,
      cssFilesRaw,
      customCss,
      jsFiles,
      jsFilesRaw,
      content,
      isSysAdmin,
      language,
      pageLanguage,
      personalizedData,
      formsData,
    } = this;
    const corporateIdentity = (this.corporateIdentity !== null)
      ? this.corporateIdentity
      : { ...this.corporateIdentityEmptyObject };
    const groupSettings = (this.groupSettings !== null)
      ? this.groupSettings
      : { ...this.groupSettingsEmptyObject };
    const variablesPlugin = (this.variablesPluginData !== null)
      ? this.variablesPluginData
      : null;
    const chatGPTPlugin = (this.chatGPTPluginData !== null)
      ? this.chatGPTPluginData
      : null;
    // add the custom css and js depending on the collection
    cssFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/templates/${this.collection}/${this.collection}.global.css`);
    jsFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/templates/${this.collection}/${this.collection}.global.js`);
    cssFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/templates/${this.collection}/${this.templateName}/style.css`);
    jsFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/templates/${this.collection}/${this.templateName}/index.js`);

    if (this.theme) {
      cssFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/themes/${this.theme}/direct.min.css`);
      jsFiles.push(`${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/themes/${this.theme}/direct.min.js`);
    }

    // build the snippetUrlString
    if (this.snippets) {
      this.snippetUrls = this.snippets.map(
        (filename) => `${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/_modules/templates/${this.collection}/${this.templateName}/_ams/snippets/${filename}`,
      );
    }

    // initialize the keditor
    const localeObject = this.$i18n.messages[this.$i18n.locale];
    const self = this;
    this.keditor = $('.keditor-article').keditor({
      autosave: function autosave(editorData) {
        // call the element
        // tell the apollo cache that we start to autosave the content
        self.$apollo.mutate({
          mutation: SetAutosaveIndicatorForHeaderInCache,
          variables: {
            isSavingData: true,
            allSaved: false,
          },
        });
        self.editorData = editorData;
        self.storeDisplayDataInComponentAndEmitAllData();
      },
      callFromKeditor: function call(functionName, data, callback) {
        switch (functionName) {
          case 'loaded':
            self.storeDisplayDataInComponentAndEmitAllData();
            break;
          case 'deleteSnippetModal':
            // trigger the open modal window
            self.$root.$emit('bv::show::modal', 'delete-snippet');
            // reference the callback in a vue data property so it can be called from the modal
            self.confirmDeleteCallbackReference = callback;
            break;
          case 'imageUploadModal':
            if (data.filetype && (data.filetype.includes('audio/mp3') || data.filetype.includes('audio/mpeg'))) {
              self.mediaUploadFileType = 'audio';
            } else if (data.filetype && (data.filetype.includes('video/mp4'))) {
              self.mediaUploadFileType = 'video';
            } else if (data.filetype && (data.filetype.includes('video/webm'))) {
              self.mediaUploadFileType = 'video_webm';
            } else if (data.filetype && (data.filetype.includes('application/pdf'))) {
              self.mediaUploadFileType = 'pdf';
            } else {
              self.mediaUploadFileType = 'image';
            }
            if (self.mediaUploadFileType !== 'video' && self.mediaUploadFileType !== 'video_webm' && self.mediaUploadFileType !== 'audio' && self.mediaUploadFileType !== 'pdf') {
              // the focalpoint arrives as a comma separated string from the keditor
              if (data.src) {
                // eslint-disable-next-line no-case-declarations
                const cloudImagePrefix = 'https://axyqwmwryo.cloudimg.io/v7/';
                // eslint-disable-next-line no-case-declarations
                let origFileLink = data.src.replace(cloudImagePrefix, '');
                if (origFileLink.includes('?') === true) {
                  origFileLink = origFileLink.substring(0, origFileLink.indexOf('?'));
                }
                // eslint-disable-next-line no-param-reassign
                data.src = origFileLink;
              }
              const focalpointArray = (data?.focal) ? data?.focal?.split(',') : false;
              let focalpoint = {};
              if (focalpointArray) {
                focalpoint = { x: focalpointArray[0], y: focalpointArray[1] };
              } else {
                focalpoint = self.imageUrlForModalImageUpload.focalpoint;
              }
              let personalization = null;
              if (data.personalization) {
                personalization = data.personalization;
              } else if (self.imageUrlForModalImageUpload.personalization) {
                personalization = self.imageUrlForModalImageUpload.personalization;
              }
              self.imageUrlForModalImageUpload = {
                ...self.imageUrlForModalImageUpload,
                url: data.src,
                focalpoint,
                personalization,
              };
            } else {
              self.imageUrlForModalImageUpload = {
                url: data.src,
                type: data.filetype,
              };
            }
            // Show image upload modal
            self.$root.$emit('bv::show::modal', 'image-upload');
            // reference the callback in a vue data property, so it can be called from the modal
            self.uploadImageCallbackReference = callback;
            break;
          case 'contextModal':
            self.contextData = data;
            self.showPersonalizationModal = true;
            // reference the callback in a vue data property, so it can be called from the modal
            self.contextCallbackReference = callback;
            break;
          case 'makeToast':
            self.makeToast(data);
            break;
          case 'stopEditorLoader':
            self.$apollo.mutate({
              mutation: SetLoadingIndicator,
              variables: {
                isIndicatorLoading: false,
              },
            });
            self.pageTour();
            break;
          case 'onActive':
            self.onidle();
            break;
          default:
            // just show if the callFromEditor is called but not catched in any switch case
            console.log('CALLBACK KEDITOR functionName', functionName, data, callback);
        }
      },
      allPageSiblings: this.pageSiblings,
      prevNextSiblings: this.prevNextPageSiblings,
      css: cssFiles,
      cssRaw: cssFilesRaw,
      js: jsFiles,
      jsRaw: jsFilesRaw,
      templateName: `${this.templateName}`,
      previewUrl: `/wps/_modules/templates/${this.collection}/${this.templateName}/`,
      content,
      customCss,
      isSysAdmin,
      language,
      pageLanguage,
      personalizedData,
      formsData,
      corporateIdentity,
      variablesPlugin,
      chatGPTPlugin,
      groupSettings,
      privileges: this.privileges,
      cloudImageFunction: this.$makeCloudImage,
      checkForCloudImage: this.$checkForCloudImage,
      locale: { localeObject },
      snippets: this.snippetUrls,
      isNewPage: this.isNewPage,
      env: process.env,
    });
  },
  methods: {
    deleteSnippet() {
      this.confirmDeleteCallbackReference(true);
      this.confirmDeleteCallbackReference = null;
    },
    addImageToEditor(imageObj) {
      this.uploadImageCallbackReference([imageObj]);
      this.uploadImageCallbackReference = null;
    },
    addContextToEditor(context) {
      this.showPersonalizationModal = false;
      this.contextCallbackReference(context);
    },
    async storeDisplayDataInComponentAndEmitAllData() {
      this.displayData = await this.keditor.getdisplaydata();
      this.emitAllData();
    },
    emitAllData() {
      const { displayData, editorData } = this;
      const editorContent = {
        displayData,
        editorData,
      };
      this.$emit('get-editor-data', editorContent);
    },
    makeToast(data) {
      this.$bvToast.toast(data.message, {
        title: data.title || 'Webmag',
        toaster: 'b-toaster-bottom-left',
        solid: true,
        autoHideDelay: 2000,
      });
    },
    onidle() {
      this.$emit('onidle');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/deep/ .keditor-article iframe {
  width: 100%;
  height: calc(100vh - 65px);
  border: 0;
}
</style>
