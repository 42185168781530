var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "keditor-article" }),
      _c("modal-delete-snippet", {
        on: { "delete-snippet": _vm.deleteSnippet }
      }),
      _c("modal-uploading-image", {
        attrs: {
          "image-object": _vm.imageUrlForModalImageUpload,
          type: _vm.computedMediaType,
          personalization: _vm.personalizedData
        },
        on: { "image-uploaded": _vm.addImageToEditor }
      }),
      _vm.showPersonalizationModal
        ? _c("modal-personalization-editor", {
            attrs: {
              personalization: _vm.personalizedData,
              "context-data": _vm.contextData
            },
            on: {
              "editor-context": _vm.addContextToEditor,
              "cancel-modal": function($event) {
                _vm.showPersonalizationModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }